import React, { useState, useEffect } from 'react';
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import { Button, IconButton } from '@mui/material';
function InstallPWA() {
  const [installPrompt, setInstallPrompt] = useState(null);
  const [isAppInstalled, setIsAppInstalled] = useState(false);

  useEffect(() => {
    // Listen for the beforeinstallprompt event
    const handleBeforeInstallPrompt = (e) => {
      // Prevent the default prompt
      e.preventDefault();
      // Store the event for later use
      setInstallPrompt(e);
    };

    // Listen for app installed event
    const handleAppInstalled = () => {
      setIsAppInstalled(true);
      setInstallPrompt(null);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);

    // Cleanup event listeners
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!installPrompt) return;

    // Show the install prompt
    installPrompt.prompt();

    // Wait for user's choice
    const { outcome } = await installPrompt.userChoice;
    console.log(`User response: ${outcome}`);

    // Reset the installPrompt state
    setInstallPrompt(null);
  };

  // Only show the button if the install prompt is available and app is not installed
  if (!installPrompt || isAppInstalled) return null;

  return (
      <Button color="primary" variant='outlined' aria-label="install" startIcon={<AddToHomeScreenIcon />} onClick={handleInstallClick}>
        התקן אפליקציה
      </Button>
  );
}

export default InstallPWA;