import React, { useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';
import { BsUpload } from 'react-icons/bs';
import { useOutletContext } from 'react-router-dom';

import MyModal from '../my-modal/MyModal';
import './userProfile.scss';
import { postData } from '../../utils/http';
import MyInput from '../my-input/MyInput';
import { getAvatarPath } from '../../utils/pathes';

const defUser = {
  email: '',
  full_name: '',
  id: 0,
};

export default function UserProfile(props) {
  const [user, setUser] = useState(defUser);
  const [selectedFile, setSelectedFile] = useState('');
  const [preview, setPreview] = useState('');
  const [croppedPreview, setCroppedPreview] = useState('');
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(false);
  const [password, setPassword] = useState('');
  const [validErr, setValidErr] = useState(false);
  const [contextUser] = useOutletContext();
  const cropperRef = useRef(null);
  

  useEffect(() => {
    if (Object.hasOwn(contextUser, 'id')) {
      setUser(contextUser);
    }
  }, [contextUser]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedPreview(cropper.getCroppedCanvas().toDataURL());
  };

  const upload = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
    handleOpen();
  };

  const edit = () => {
    if (!editable) {
      setEditable(true);
    } else {
      submit();
      setEditable(false);
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'password') {
      setPassword(value);
    } else {
      setUser((state) => ({ ...state, [name]: value }));
    }
  };

  const save = () => {
    setPreview(croppedPreview);
    handleClose();
  };

  const submit = () => {
    const payload = {
      user,
      avatarPath: croppedPreview
        ? getAvatarPath(user?.id) // eslint-disable-line no-undef
        : user?.avatar,
      avatar: croppedPreview
        ? croppedPreview.toString().replace('data:image/png;base64,', '')
        : null,
    };
    postData('auth/update-profile', payload)
      .then((res) => {
        window.alert('פרטים עודכנו בהצלחה');
      })
      .catch((err) => {
        window.alert('אירעה שגיאה');
      });
  };

  const changePassword = () => {
    if (!validErr) {
      postData('auth/change-password', { password, user })
        .then((res) => {
          window.alert('פרטים עודכנו בהצלחה');
        })
        .catch((err) => {
          console.log(err);
          window.alert('אירעה שגיאה');
        });
    }
  };

  return (
    <Box className="user-profile">
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '15em' },
        }}
        noValidate
        autoComplete="off"
      >
        <Box margin={2}>
          <label htmlFor="file-input">
            <Avatar
              alt={user?.full_name}
              src={preview || user?.avatar}
              sx={{ width: 90, height: 90 }}
            >
              <Box className="pointer center-text upload">
                <BsUpload />
                <Box>העלה תמונה</Box>
              </Box>
            </Avatar>
          </label>
          <TextField
            type="file"
            variant="outlined"
            className="file-input"
            id="file-input"
            disabled={!editable}
            onChange={upload}
          />
        </Box>
        <Box>
          <TextField
            type="text"
            variant="outlined"
            label="שם מלא"
            name="full_name"
            value={user?.full_name}
            disabled={!editable}
            InputLabelProps={{ shrink: !!user?.full_name }}
            onChange={handleChange}
          />
        </Box>
        <Box>
          <TextField
            type="email"
            variant="outlined"
            label='דוא"ל'
            name="email"
            value={user?.email}
            disabled={!editable}
            InputLabelProps={{ shrink: !!user?.email }}
            onChange={handleChange}
          />
        </Box>
        {/* <Box>
					<TextField
						id="file-input"
						type="text"
						variant="outlined"
						label="תואר"
						name="title"
						value={user.title || ''}
						disabled={!editable}
						InputLabelProps={{ shrink: !!user.title }}
						onChange={handleChange}
					/>
				</Box> */}
        <Box>
          <TextField
            type="text"
            variant="outlined"
            label="אודות"
            name="about"
            value={user?.about || ''}
            disabled={!editable}
            InputLabelProps={{ shrink: !!user?.about }}
            onChange={handleChange}
          />
        </Box>
        <Box marginLeft={1}>
          <Button variant="contained" color="primary" onClick={edit}>
            {editable ? 'שמור' : 'ערוך'}
          </Button>
        </Box>
        <Box marginTop={5}>
          <MyInput
            type="password"
            handleChange={handleChange}
            value={password}
            name="password"
            label="עדכן סיסמה"
            setValidErr={setValidErr}
            autoComplete="current-password"
            validator='password'
          />
        </Box>
        <Box marginLeft={1}>
          <Button disabled={password.length < 8} variant="contained" color="primary" onClick={changePassword}>
            שמור
          </Button>
        </Box>
      </Box>
      <MyModal open={open} handleOpen={handleOpen} handleClose={handleClose}>
        <Cropper
          src={preview}
          style={{ height: 400, width: '100%' }}
          aspectRatio={1}
          guides={false}
          crop={onCrop}
          ref={cropperRef}
        />
        <Button variant="contained" color="primary" onClick={save}>
          שמור
        </Button>
      </MyModal>
    </Box>
  );
}
