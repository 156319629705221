import React from 'react';
import { Box, Typography } from '@mui/material';
import './home.scss';
import ClassesSection from '../classes-section/ClassesSection';
import Contact from '../contact/Contact';

// import Dedication from '../dedication/Dedication';

export default function Home() {



	return (
		<Box className="home">
			<Box className="bg">
				<Box className="main-heading">
					<Typography className="heading-part-1">לימוד</Typography>
					<Typography className="heading-part-2 font-secular">התורה כולה</Typography>
					<Typography className="heading-part-3">
            במסלולי הרמב"ם היומי
					</Typography>
				</Box>
				<ClassesSection />
				<Contact />
				{/* <Dedication /> */}
			</Box>
		</Box>
	);
}
