import React from 'react';

import { Box, Button } from '@mui/material';

import './trackPicker.scss';
import { TRACK_DATA } from '../../utils/constants';

export default function TrackPicker(props) {
	const handleClick = (e) => {
		props.setTrack(e.target.id);
		props.onPick && props.onPick();
	};

	return (
		<Box className="track-picker">
			<Button
				id="three"
				variant="contained"
				color={props.track === 'three' ? 'primary' : 'transparent'}
				className={'picker-btn ' + (props.track === 'three' ? 'picked-btn' : '')}
				onClick={handleClick}
			>
        שלושה <br />
				פרקים ליום
			</Button>
			<Button
				style={{ flexDirection: 'column' }} // remove after uploading
				id="sefer"
				variant="contained"
				color={props.track === 'sefer' ? 'primary' : 'transparent'}
				className={'picker-btn ' + (props.track === 'sefer' ? 'picked-btn' : '')}
				onClick={handleClick}
			>
        ספר <br />
				המצוות
			</Button>
			<Button
				id="one"
				variant="contained"
				className={'picker-btn ' + (props.track === 'one' ? 'picked-btn' : '')}
				color={props.track === 'one' ? 'primary' : 'transparent'}
				onClick={handleClick}
			>
        פרק אחד <br />
				ליום
			</Button>
		</Box>
	);
}
