const PATH = process.env.REACT_APP_API_URL;  // eslint-disable-line no-undef

const getAvatarPath = (id) => {
	return `${PATH}images/lecturer${id}.webp`;
};

const getDefaultAvatarPath = () => {
	return `${PATH}images/default-avatar.png`;
};

export {getAvatarPath, getDefaultAvatarPath };



