import React, { useState } from 'react';

import { Box, TextField, Typography, Button, Link } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
// import {
// 	validEmailRegex,
// 	validFullNameRegex,
// 	validateForm,
// } from '../../utils/validation';

import './contact.scss';
import { mailTo } from '../../utils/mailto';

export default function Contact(props) {
	// const [fullname, setFullname] = useState('');
	// const [email, setEmail] = useState('');
	// const [showSuccess, setShowSuccess] = useState(false);
	// const [showErr, setShowErr] = useState(false);
	// const [submited, setSubmited] = useState(false);
	// const [errors, setErrors] = useState({
	// 	fullname: 'שם מלא נדרש',
	// 	email: 'אנא הזן כתובת דוא"ל תקינה',
	// 	password: 'על הסיסמה להכיל לפחות 8 תווים',
	// });

	// const handleChange = (e) => {
	//   setSubmited(false);
	//   setShowErr(false);
	//   setShowSuccess(false);
	//   const name = e.target.name;
	//   const value = e.target.value;
	//   if (name === "fullname") {
	//     setFullname(value);
	//   } else if (name === "email") {
	//     setEmail(value);
	//   }

	//   switch (name) {
	//     case "fullname":
	//       setErrors({
	//         ...errors,
	//         fullname: validFullNameRegex.test(value) ? "" : "שם מלא נדרש",
	//       });
	//       break;
	//     case "email":
	//       setErrors({
	//         ...errors,
	//         email: validEmailRegex.test(value) ? "" : 'אנא הזן כתובת דוא"ל תקינה',
	//       });
	//       break;
	//     default:
	//       break;
	//   }
	// };

	const submit = (e) => {
		e.preventDefault();
		// setSubmited(true);
		mailTo('');
		// const mail = document.createElement('a');
		// mail.href = 'mailto:mail@example.org?subject=אני מעוניין להעלות את שיעורי לאתר';
		// mail.target = '_blank';
		// mail.click();
	};

	return (
		<Box className="lecturer-contact">
			<Box
				className="center-text lect-form"
				// component="form"
				sx={{
					'& .MuiTextField-root': { m: 1, width: '35ch' },
				}}
				noValidate
				autoComplete="off"
				// onSubmit={submit}
			>
				<Typography fontWeight={500} fontSize={20}>
				להקדשת יום בלימוד הרמב"ם, ולפרטים נוספים על האתר השאר הודעה למערכת.
				</Typography>
				{/* <Typography fontWeight={600}>צור קשר</Typography> */}
				{/* <Box className="input-box">
          <TextField
            type="text"
            size="small"
            label="שם מלא"
            variant="outlined"
            name="fullname"
            value={fullname}
            onChange={handleChange}
          />
          {submited && errors.fullname && (
            <Typography className="input-err">{errors.fullname}</Typography>
          )}
        </Box>
        <Box className="input-box">
          <TextField
            type="email"
            size="small"
            label='דוא"ל'
            variant="outlined"
            color="primary"
            name="email"
            value={email}
            onChange={handleChange}
          />
          {submited && errors.email && (
            <Typography className="input-err">{errors.email}</Typography>
          )}
        </Box> */}
				<Box display="flex" alignItems="center" justifyContent="center" gap={2}>
					<Button
						className="submit-btn"
						type="submit"
						variant="outlined"
						color="primary"
						onClick={submit}
					>
						<MailOutlineIcon />
						<Typography className='assistant' textTransform='lowercase'> &nbsp;&nbsp; rambam.haazinu@gmail.com</Typography>
					</Button>
					{/* <Link href="tel:+97254-942-7098">
						<Button 
							className="submit-btn"
							type="submit"
							variant="outlined"
							color="primary"
						>
							<PhoneIcon />
						</Button>
					</Link> */}
				</Box>
			</Box>
		</Box>
	);
}