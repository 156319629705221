import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import {
	createTheme,
	ThemeProvider,
} from '@mui/material/styles';


import Layout from './components/layout/Layout';

import Rtl from './utils/RTL';
import './App.scss';
import { clearLocalStorage } from './utils/localStorage';

const theme = createTheme({
	palette: {
		primary: {
			main: '#3a3a52',
		},
		secondary: {
			main: '#f3e8d3',
		},
		transparent: {
			main: 'transparent',
		},
	},
	direction: 'rtl',
});

export default function App() {
	const navigate = useNavigate();
	useEffect(() => {
		document.body.onkeydown = (e) => {
			if (e.ctrlKey && e.key === 'F1') {
				navigate('/login');
			} else if (e.ctrlKey && e.key === 'F2') {
				clearLocalStorage();
				navigate('/');
			}
		};
	}, []);

	const isProduction = process.env.NODE_ENV === 'production'; // eslint-disable-line no-undef
	return (
		<CacheBuster
			currentVersion={packageInfo.version}
			isEnabled={isProduction} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			// loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
			metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
		>
			<Rtl>
				<ThemeProvider theme={theme}>
					<Layout></Layout>
				</ThemeProvider>
			</Rtl>
		</CacheBuster>

	);
}